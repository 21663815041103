.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


a {
  text-decoration: none !important; 
}

.container{
  font-family: "Maven Pro", sans-serif;
  font-optical-sizing: auto;
  font-weight: 400;
  font-style: normal;
}

.puruliaresort{
  background-color: #e1e7f3;
}

.puruliahotelbook{
  background-color: #dfe2ec;
  padding: 1px;
}


.btn-grad57 {
  background-image: linear-gradient(to right, #1c434b 0%, #8ed6ee  51%, #044c80  100%);
  margin: 0px;
  padding: 6px 20px;
  text-align: center;
  /* text-transform: uppercase; */
  transition: 0.5s;
  background-size: 200% auto;
  color: white;            
  box-shadow: 0 0 20px #eee;
  border-radius: 6px;
  display: block;
}

.btn-grad57:hover {
  background-position: right center; /* change the direction of the change here */
  color: #fff;
  text-decoration: none;
}






* {box-sizing: border-box}

/* Container needed to position the overlay. Adjust the width as needed */
.hoverimage {
  position: relative;

}

/* Make the image to responsive */
.image1 {
  display: block;
  width: 100%;
  height: auto;
}

/* The overlay effect - lays on top of the container and over the image */
.overlay {
  position: absolute;
  bottom: 0;
  background: rgb(0, 0, 0);
  background: rgba(0, 0, 0, 0.5); /* Black see-through */
  color: #f1f1f1;
  width: 100%;
  transition: .5s ease;
  opacity:0;
  color: white;
  font-size: 20px;
  padding: 20px;
  text-align: center;
}

/* When you mouse over the container, fade in the overlay title */
.hoverimage:hover .overlay {
  opacity: 1;
}