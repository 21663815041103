

.kps{
    background-color : #fdf7ee;

}

.bestresortpurulia{
    background-color: #243d50; /* Green */
    border: none;
    color: white;
    padding: 7px 22px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 15px;
    border-radius: 5px;
  }
  