.krishnagarjagadhatritraditionalpuja{
    background-color: rgb(252, 225, 241);
}

.no1restuarantkrishnagar{
    background-color: rgb(223, 250, 224);
}



.maajagadhatri{
    height:  400px;
    width: 100%;
    background-position: center;
    background-size: cover;
    background-image: url(https://i.postimg.cc/j5MCn8BV/Whats-App-Image-2024-11-02-at-2-41-50-PM.jpg);
    background-attachment: fixed;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: 'Josefin Sans', sans-serif;
}

.fontbold h1{
    font-size: 60px;
}



.contactbutton{
    background-color: rgb(23, 74, 100); /* Green */
    border: none;
    color: white;
    padding: 5px 18px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    margin: 4px 2px;
    transition-duration: 0.4s;
    cursor: pointer;
    border-radius: 4px;
  }