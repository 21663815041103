.buttonmenu{
    background-color: rgb(16, 50, 70); /* Green */
    border: none;
    color: white;
    padding: 5px 17px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 15px;
    margin: 2px 2px;
    transition-duration: 0.4s;
    cursor: pointer;
    border-radius: 12px;
}

.textcolor h2{ 
    color: rgb(21, 122, 138);
}

.textcolor h6{ 
    color: rgb(21, 122, 138);
}