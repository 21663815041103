.marqueeback{
    background-color: rgb(47, 100, 104);
}


.justifytextname{
    text-align: justify;
    text-justify: inter-word;
}

.textcolor h1{
    color: rgb(9, 101, 115);
}

.textcolor h3{
    color: rgb(9, 101, 115);
}


.viewdetials{
    background-color: #1f525a; /* Green */
    border: none;
    color: white;
    padding: 6px 18px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    border-radius: 5px;
}

.viewdetialscall{
    background-color: #0a7889; 
    border: none;
    color: white;
    padding: 6px 18px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    border-radius: 5px;
}

.whatsapp{
    background-color: #28865f; 
    border: none;
    color: white;
    padding: 6px 18px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    border-radius: 5px;
}